<template>
  <div class="exchangeMember">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">领取兑换</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="exchangeBox">
        <van-field v-model="exchangeCode" label="兑换码：" placeholder="请输入VIP兑换码" />
      </div>
      <div class="exchangBtn" @click="confirmExchange">立即兑换</div>
      <div class="exchangeTable">
        <div class="cell">
          <div class="title">兑换码</div>
          <div class="title">类型</div>
          <div class="title">兑换时间</div>
        </div>
        <div class="cellList" v-if="list.length > 0">
          <div class="cellBox" v-for="item in list" :key="item.code">
            <div class="cellItem">{{ item.code }}</div>
            <div class="cellItem">{{ item.authority }}</div>
            <div class="cellItem">{{ item.updatedAt | time }}</div>
          </div>
        </div>
        <div class="cellList" v-else>
          <span>暂无兑换记录</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Toast } from 'vant';
import { exchageCode, exchangeList } from '@/api/mine';
export default {
  name: 'ExchangeMember',
  components: {
    [Field.name]: Field,
  },
  data() {
    return {
      exchangeCode: '',
      list: [],
    };
  },
  created() {
    this.getExchangeList();
  },
  methods: {
    async getExchangeList() {
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(exchangeList, null);
        if (ret.code === 200 && ret.data) {
          this.list = ret.data;
        } else if (ret.tip) {
          Toast(ret.tip || '获取数据异常');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('获取数据异常');
      }
    },
    async confirmExchange() {
      if (!this.exchangeCode) {
        Toast('请输入兑换码');
        return;
      }
      let req = {
        code: this.exchangeCode,
      };
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(exchageCode, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch('user/getUserInfo');
          this.getExchangeList();
          Toast('兑换成功');
        } else if (ret.tip) {
          Toast(ret.tip || '兑换失败');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('兑换失败');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.exchangeMember {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    padding: 37px 12px;
    .exchangeBox {
      border-radius: 6px;
      overflow: hidden;
      /deep/ .van-field__label {
        font-size: 16px;
        width: unset;
        margin-right: unset;
      }
    }
    .exchangBtn {
      margin: 28px 0 47px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      background-color: rgb(255, 103, 143);
      font-size: 16px;
      text-align: center;
      border-radius: 4px;
    }
    .exchangeTable {
      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .title {
          flex: 2;
          font-size: 16px;
          text-align: center;
        }
        .title:first-of-type {
          flex: 1.5;
        }
      }
      .cellList {
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        span {
          display: inline-block;
          margin-top: 50px;
        }
        .cellBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(143, 123, 200, 0.06);
          .cellItem {
            flex: 2;
            font-size: 14px;
            height: 47px;
            line-height: 47px;
            text-align: center;
            background-color: #fff;
            color: rgb(102, 102, 102);
            overflow: hidden;
            white-space: nowrap;
          }
          .cellItem:first-of-type {
            flex: 1.5;
          }
          .cellItem:last-of-type {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
